import { api } from './common'

export const getRevisions = (collectionId, token) =>
  api({
    method: 'GET',
    path: `/builder/quiz/${collectionId}/revision`,
    token,
  })

export const getSubRevisions = (collectionId, ids, token) =>
  api({
    method: 'GET',
    path: `/builder/quiz/${collectionId}/revision/${ids.toString()}`,
    token,
  })

export const createRevision = (data, token) =>
  api({
    method: 'POST',
    path: `/builder/quiz/${data.quiz.id}/revision`,
    body: {
      data: data.quiz,
      description: data.description,
    },
    token,
  })

export const updateRevision = (revision, token) =>
  api({
    method: 'PUT',
    path: `/builder/quiz/${revision.data.id}/revision/${revision.id}`,
    body: revision,
    token,
  })
