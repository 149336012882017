export const initialState = {
  users: [],
  groups: [],
}

export default {
  setUsers: (state = initialState, action) => ({
    ...state,
    users: action.users,
  }),
  addUser: (state = initialState, action) => ({
    ...state,
    users: [...state.users, action.user],
  }),
  updateUser: (state = initialState, action) => {
    const idx = state.users.findIndex((u) => u.id === action.user.id)
    return {
      ...state,
      users: [...state.users.slice(0, idx), action.user, ...state.users.slice(idx + 1)],
    }
  },
  setGroups: (state = initialState, action) => ({
    ...state,
    groups: action.groups,
  }),
  addGroup: (state = initialState, action) => ({
    ...state,
    groups: [action.group, ...state.groups.filter((g) => g.id !== action.group.id)],
  }),
  removeGroup: (state = initialState, action) => ({
    ...state,
    groups: state.groups.filter((g) => g.id !== action.groupId),
  }),
}
