import { quizAllChecks } from 'src/services/quizChecks'
import {
  getQuizList,
  getQuiz,
  createQuizOnly,
  patchQuiz,
  saveQuizOnly,
  updateWeights,
  updateStyles,
  delQuiz,
  cloneQuiz,
  switchQuiz as switchQuizService,
  setTags as setTagsService,
  createRevision,
} from 'src/services/api'

import { quizStatus } from 'src/constants'
import { getChangesCount, getChanges, getQuizFromState } from 'src/store/reducers/quizUtils'
import { fileUploads, setQuizUpdateInfo, genNewQuiz, reportChecks } from './common'

let autosaveIntervalId = null

const buildChanges = (state, quizId) => {
  return getChanges(state.quiz, quizId).reduce(
    (acc, change) => {
      let withChanges = false

      if (change.action === 'merge') {
        Object.assign(acc.quiz, change.value)
        acc.times.push(change.time)
        return acc
      }

      const pathLen = change.path.length
      const lastPath = change.path[pathLen - 1]
      const prevLastPath = change.path[pathLen - 2]

      if (change.action === 'splice' && prevLastPath === 'questions' && lastPath.id) {
        acc.questionsRemoved.push(change.path[pathLen - 1].id)
        withChanges = true
      }

      if (change.action === 'push' && lastPath === 'sequencing') {
        const newSequencing = change.value
        // if the sequencing with that parameters has been removed earlier, then we don't need to take that
        const removed = acc.sequencesRemoved.filter(
          (s) =>
            s.type !== newSequencing.type ||
            s.questionId !== newSequencing.questionId ||
            s.nextQuestionId !== newSequencing.nextQuestionId ||
            (newSequencing.answerId && s.answerId !== newSequencing.answerId)
        )

        acc.sequencesCreated.push(newSequencing)
        acc.sequencesRemoved = removed
        withChanges = true
      }

      if (change.action === 'splice' && prevLastPath === 'sequencing') {
        // if the sequencing with that parameters has been added earlier, then we don't need to take that
        const created = acc.sequencesCreated.filter(
          (s) =>
            s.type !== lastPath.type ||
            s.questionId !== lastPath.questionId ||
            s.nextQuestionId !== lastPath.nextQuestionId ||
            (lastPath.answerId && s.answerId !== lastPath.answerId)
        )

        acc.sequencesRemoved.push(lastPath)
        acc.sequencesCreated = created
        withChanges = true
      }

      if (change.action === 'replace' && prevLastPath === 'resultWeights') {
        const newWeight = change.value
        const prevUpdates = acc.weightsUpdated.filter(
          (w) => w.answerId !== newWeight.answerId || w.resultId !== newWeight.resultId
        )
        prevUpdates.push(newWeight)
        acc.weightsUpdated = prevUpdates
        withChanges = true
      }

      if (change.action === 'reorder' && lastPath === 'results') {
        const stateQuiz = getQuizFromState(state.quiz, quizId)
        const newOrder = stateQuiz.results.map((result, i) => ({ id: result.id, order: i }))
        acc.resultsReorder = newOrder
        withChanges = true
      }

      if (change.action === 'replace' && prevLastPath === 'interstitials') {
        const newItem = change.value
        const prevUpdates = acc.interstitialsUpdated.filter((i) => i.id !== newItem.id)
        prevUpdates.push(newItem)
        acc.interstitialsUpdated = prevUpdates
        withChanges = true
      }

      // Only push time if there are changes so we can avoid sending the
      // request if there are no times
      if (withChanges) {
        acc.times.push(change.time)
      }

      return acc
    },
    {
      quiz: {},
      questionsRemoved: [],
      sequencesCreated: [],
      sequencesRemoved: [],
      weightsUpdated: [],
      interstitialsUpdated: [],
      times: [],
    }
  )
}

const stopAutosaveProccess = () => clearTimeout(autosaveIntervalId)

const startAutosaveProccess = (quiz, state, api, quizId) => {
  if (getChangesCount(state.quiz, quizId) > 0) {
    const changes = buildChanges(state, quizId)
    const { token } = state.session

    if (changes.times.length) {
      api.loading({ name: 'quiz/autosave' })

      return patchQuiz(quizId, changes, token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }

          quiz.finishAutosave({ quiz: res.quiz, times: changes.times })
          createRevision({ quiz: getQuizFromState(state.quiz, quizId) }, token)
          api.finish({ name: 'quiz/autosave' })
        })
        .catch((error) => {
          api.error({ name: 'quiz/autosave', error })
        })
    }
  }

  return Promise.resolve()
}

export default function (quiz, state, api) {
  const getList = () => {
    api.loading({ name: 'quiz/read' })
    getQuizList(state.session.token)
      .then((res) => {
        api.finish({ name: 'quiz/read' })
        quiz.setList({ list: res.results })
      })
      .catch((error) => {
        api.error({ name: 'quiz/read', error })
      })
  }

  const load = (collectionId) => {
    api.loading({ name: 'quiz/load' })

    return getQuiz(collectionId, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        quiz.loadFromSrv({ quiz: res })
        reportChecks(quiz, res.id, quizAllChecks(res))
      })
      .catch((error) => {
        api.error({ name: 'quiz/load', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/load' })
      })
  }

  const create = (data) => {
    const newQuiz = genNewQuiz(state, data)
    api.loading({ name: 'quiz/save' })
    return createQuizOnly(newQuiz, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        quiz.replaceSrv({ quiz: res.quiz })
        return res.quiz
      })
      .catch((error) => {
        api.error({ name: 'quiz/save', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/save' })
      })
  }

  const save = (localQuiz) => {
    if (getChangesCount(state.quiz, localQuiz.id) > 0) {
      api.loading({ name: 'quiz/save' })
      stopAutosaveProccess()

      const changes = buildChanges(state, localQuiz.id)

      if (changes.times.length) {
        return patchQuiz(localQuiz.id, changes, state.session.token)
          .then((res) => {
            if (res.error) {
              throw res.error
            }

            quiz.replaceLocal({
              localId: localQuiz.id,
              quiz: localQuiz,
            })

            api.finish({ name: 'quiz/save' })
            return localQuiz
          })
          .catch((error) => {
            api.error({ name: 'quiz/save', error })
            throw error
          })
      }
    }
    return Promise.resolve(localQuiz)
  }

  const autosave = ({ quizId }, urgently = false) => {
    stopAutosaveProccess()
    if (urgently) {
      return startAutosaveProccess(quiz, state, api, quizId)
    } else if (getChangesCount(state.quiz, quizId) > 0) {
      autosaveIntervalId = setTimeout(() => startAutosaveProccess(quiz, state, api, quizId), 2000)
    }
    return Promise.resolve()
  }

  const updateQuiz = (localQuiz, changes, section) => {
    api.loading({ name: 'quiz/save' })
    stopAutosaveProccess()

    const { token } = state.session

    return fileUploads({ id: localQuiz.id, ...changes }, token)
      .then((q) => saveQuizOnly(q, section, token))
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        const updatedQuiz = { ...localQuiz, ...res.quiz }
        quiz.replaceQuiz({ quiz: updatedQuiz })
        createRevision({ quiz: updatedQuiz }, token)

        return updatedQuiz
      })
      .catch((error) => {
        api.error({ name: 'quiz/save', error })
      })
      .finally(() => {
        api.finish({ name: 'quiz/save' })
      })
  }

  const updateAndSaveWeights = ({ quiz: localQuiz, weights }) => {
    api.loading({ name: 'quiz/save' })

    const { token } = state.session

    updateWeights(localQuiz.id, weights, token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        const weightMaps = res.weights.reduce((acc, weight) => {
          if (!acc[weight.answerId]) {
            acc[weight.answerId] = []
          }
          acc[weight.answerId].push(weight)
          return acc
        }, {})

        localQuiz.questions.forEach((question) => {
          question.answerOptions.forEach((ao) => {
            if (weightMaps[ao.id]) {
              ao.resultWeights = weightMaps[ao.id]
            }
          })
        })

        setQuizUpdateInfo(localQuiz, state.session.user)
        quiz.replaceQuiz({ quiz: localQuiz })
        createRevision({ quiz: localQuiz }, token)

        return localQuiz
      })
      .catch((error) => {
        api.error({ name: 'quiz/save', error })
      })
      .finally(() => {
        api.finish({ name: 'quiz/save' })
      })
  }

  const updateAndSaveStyles = ({ quiz: localQuiz, styles }) => {
    api.loading({ name: 'quiz/save' })

    const { token } = state.session

    return updateStyles(localQuiz.id, styles, token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        localQuiz.css = res.css
        localQuiz.styleSheet = res.styleSheet
        quiz.replaceQuiz({ quiz: localQuiz })
        createRevision({ quiz: localQuiz }, token)

        return localQuiz
      })
      .catch((error) => {
        api.error({ name: 'quiz/save', error })
      })
      .finally(() => {
        api.finish({ name: 'quiz/save' })
      })
  }

  const del = (localQuiz) => {
    api.loading({ name: 'quiz/del' })
    stopAutosaveProccess()

    return delQuiz(localQuiz.id, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        quiz.replaceQuiz({ quiz: { ...localQuiz, status: quizStatus.deleted } })
        api.finish({ name: 'quiz/del' })
      })
      .catch((error) => {
        api.error({ name: 'quiz/del', error })
        throw error
      })
  }

  const clone = (data, options) => {
    api.loading({ name: 'quiz/save' })

    const newQuiz = genNewQuiz(state, data)
    delete newQuiz.id
    delete newQuiz.quizCollectionId

    return cloneQuiz(data.id, newQuiz, options, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        quiz.replaceQuiz({ quiz: res.quiz })
        return res.quiz
      })
      .catch((error) => {
        api.error({ name: 'quiz/save', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/save' })
      })
  }

  const switchQuiz = (toQuiz, fromQuiz) => {
    api.loading({ name: 'quiz/switch' })

    return switchQuizService(toQuiz.id, fromQuiz.id, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        toQuiz.uniqueIdentifier = res.result.to
        fromQuiz.uniqueIdentifier = res.result.from
        quiz.replaceQuizzes({ quizzes: [toQuiz, fromQuiz] })

        return toQuiz
      })
      .catch((error) => {
        api.error({ name: 'quiz/switch', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/switch' })
      })
  }

  const setTags = (localQuiz, tags) => {
    api.loading({ name: 'quiz/save' })

    return setTagsService(localQuiz.id, tags, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        localQuiz.tags = tags
        quiz.replaceQuiz({ quiz: localQuiz })
      })
      .catch((error) => {
        api.error({ name: 'quiz/save', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/save' })
      })
  }

  return {
    getList,
    load,
    create,
    save,
    autosave,
    updateQuiz,
    updateAndSaveWeights,
    updateAndSaveStyles,
    del,
    clone,
    switchQuiz,
    setTags,
  }
}
