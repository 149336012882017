import {
  getRevisions as getRevisionsService,
  getSubRevisions as getSubRevisionsService,
  createRevision,
  updateRevision as updateRevisionService,
  saveQuiz,
  cloneQuiz,
  unlockQuiz,
} from 'src/services/api'

import { quizStatus } from 'src/constants'
import { genNewQuiz } from './common'

export default function (quiz, state, api, revisions) {
  const getRevisions = (collectionId) => {
    api.loading({ name: 'quiz/revisions' })

    return getRevisionsService(collectionId, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        revisions.setRevisions({
          collectionId,
          revisions: res.results,
        })
      })
      .catch((error) => {
        api.error({ name: 'quiz/revisions', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/revisions' })
      })
  }

  const getSubRevisions = (collectionId, id, subRevisions) => {
    api.loading({ name: `quiz/sub-revisions` })
    return getSubRevisionsService(
      collectionId,
      subRevisions.map((r) => r.id),
      state.session.token
    )
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        revisions.setSubRevisions({
          collectionId,
          id,
          revisions: res.results,
        })
      })
      .catch((error) => {
        api.error({ name: `quiz/sub-revisions`, error })
        throw error
      })
      .finally(() => {
        api.finish({ name: `quiz/sub-revisions` })
      })
  }

  const restoreRevision = async (revision, storeQuiz) => {
    api.loading({ name: 'quiz/save' })

    try {
      const { token } = state.session

      let res
      if (storeQuiz.status === quizStatus.published) {
        // If actual quiz its published we just create a new quiz
        const newData = {
          ...genNewQuiz(state, revision.data, revision.data?.ownerUserId),
          quizCollectionId: storeQuiz.quizCollectionId, // make sure it has the collection id in case is an old revision
          version: storeQuiz.version, // copy the version from the last quiz
        }
        res = await unlockQuiz(storeQuiz.id, newData, token)
        quiz.swapQuiz({ oldId: storeQuiz.id, newQuiz: res.quiz })
      } else {
        // If its not published save changes on the last version quiz
        const newData = {
          ...revision.data,
          id: storeQuiz.id,
          status: quizStatus.draft, // a restored version always will be draft
          quizCollectionId: storeQuiz.quizCollectionId, // make sure it has the collection id in case is an old revision
          version: storeQuiz.version, // copy the version from the last quiz
        }
        res = await saveQuiz(newData, token)
        quiz.replaceQuiz({ quiz: res.quiz })
      }

      await createRevision({ quiz: res.quiz }, token).then((created) => {
        revisions.addRevision({
          collectionId: res.quiz.quizCollectionId,
          revision: created.result,
        })
      })
    } catch (error) {
      api.error({ name: 'quiz/save', error })
    }
    api.finish({ name: 'quiz/save' })
  }

  const restoreRevisionAsCopy = (revision, options) => {
    api.loading({ name: 'quiz/save' })

    const { token } = state.session
    const newQuiz = {
      ...revision.data,
      title: options.title || `${revision.data.title} (RESTORED)`,
      id: undefined,
      quizCollectionId: undefined,
      local: undefined,
    }

    return cloneQuiz(revision.data.id, newQuiz, options, token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        quiz.replaceQuiz({ quiz: res.quiz })
        return createRevision({ quiz: res.quiz }, token).then(() => res.quiz)
      })
      .catch((error) => {
        api.error({ name: 'quiz/save', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/save' })
      })
  }

  const updateRevision = (collectionId, revision) => {
    revisions.updateRevision({
      collectionId,
      revision,
    })

    api.loading({ name: 'quiz/updateRevision' })

    return updateRevisionService(revision, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        revisions.updateRevision({
          collectionId,
          revision: res.result,
        })
      })
      .catch((error) => {
        api.error({ name: 'quiz/updateRevision', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/updateRevision' })
      })
  }

  return {
    getRevisions,
    restoreRevision,
    restoreRevisionAsCopy,
    updateRevision,
    getSubRevisions,
  }
}
