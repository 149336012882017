import { createMuiTheme } from '@material-ui/core'
import palette from './palette'
import typography from './typography'
import overrides from './overrides'
import form from './form'
import spacing from './spacing'

const baseTheme = {
  spacing,
  palette,
  typography,
  overrides,
  form,
}

export const theme = createMuiTheme(baseTheme)
export const themeWithRtl = createMuiTheme({ ...baseTheme, direction: 'rtl' })
